import { useTheme } from '@material-ui/core'
import { themeOptions } from '@xyo-network/sdk-coin-react-js'
import { CookieConsent, Fbq, FlexGrowCol, Gtag, InvertableThemeProvider, SnapTr } from '@xyo-network/sdk-xyo-react-js'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'

import { ApiProvider, AuthProvider, PixelProvider, RebillyApiProvider, SettingsLoader, useSettings } from './Contexts'
import { StartPages } from './Pages'
import RedirectEx from './RedirectEx'

Fbq.init('339003476728548')
Gtag.init('G-TCJLRQCJ2H', 'AW-989965544', ['app.coinapp.co', 'coinapp.co', 'localhost', 'localhost:3000'])
SnapTr.init('29bf72a5-b8bd-4601-85bc-847bf293f17a')
//Ttq.init('C1EJK2K8PMMOGUUN29P0')

const AppRoutes: React.FC = () => {
  const location = useLocation()
  console.log(`Location: ${location.pathname}`)
  return (
    <Switch>
      <Route component={StartPages} exact path="/" />
      <Route>
        <RedirectEx host={'https://app.coinapp.co'} to={location.pathname} />
      </Route>
    </Switch>
  )
}

const AppBody: React.FC = () => {
  const theme = useTheme()
  return (
    <RebillyApiProvider>
      <PixelProvider id="app.coinapp.co">
        <Router>
          <FlexGrowCol
            width="100vw"
            minHeight="100vh"
            justifyContent="flex-start"
            alignContent="stretch"
            bgcolor={theme.palette.background.default}
            color={theme.palette.text.primary}
          >
            <AppRoutes />
            <CookieConsent />
          </FlexGrowCol>
        </Router>
      </PixelProvider>
    </RebillyApiProvider>
  )
}

const AppThemeBody: React.FC = () => {
  const { darkMode } = useSettings()
  return (
    <InvertableThemeProvider dark={darkMode} options={themeOptions}>
      <AppBody />
    </InvertableThemeProvider>
  )
}

const App: React.FC = () => {
  return (
    <>
      <Helmet defaultTitle="COIN App" titleTemplate="%s | COIN App">
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-TCJLRQCJ2H" />
        <script async src="https://connect.facebook.net/en_US/fbevents.js" />
        <script async src="https://sc-static.net/scevent.min.js" />
      </Helmet>
      <SettingsLoader>
        <AuthProvider>
          <ApiProvider>
            <InvertableThemeProvider options={themeOptions}>
              <AppThemeBody />
            </InvertableThemeProvider>
          </ApiProvider>
        </AuthProvider>
      </SettingsLoader>
    </>
  )
}

export default App
