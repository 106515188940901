import { Redirect, RedirectProps } from 'react-router-dom'

interface Props extends RedirectProps {
  host?: string
}

const RedirectEx: React.FC<Props> = (props) => {
  const { host, to, ...redirectProps } = props
  if (host) {
    setTimeout(() => {
      document.location.replace(`${host}${to}`)
    }, 0)
    return null
  } else {
    return <Redirect to={to} {...redirectProps} />
  }
}

export default RedirectEx
